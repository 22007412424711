export default {
    clientID: 'tfGt6biRgXpU8rR8VvqnjqrbljLkffVV',
    domain: process.env.NODE_ENV === 'production' ? 'icancha-app.auth0.com' : 'auth.icancha.com',
    options: {
        closable: true,
        autoclose: true,
        allowSignUp: false,
        allowShowPassword: true,
        language: 'es',
        rememberLastLogin: false,
        languageDictionary: {
            title: 'by iCancha',
            loginSubmitLabel: 'Ingresar',
            emailInputPlaceholder: 'info@icancha.com',
            passwordInputPlaceholder: 'contraseña',
        },
        theme: {
            primaryColor: '#3741fa',
            logo: 'https://firebasestorage.googleapis.com/v0/b/icancha-co.appspot.com/o/multimedia-icancha%2FLogoVerticalSARi128.png?alt=media&token=0c169ab0-b2d6-4f7e-8686-76c4d8de7871',
        },
        auth: {
            responseType: 'token id_token',
            scope: 'openid profile email read',
            audience: 'https://icancha.com',
            redirectUrl: process.env.NODE_ENV === 'production' ? 'https://icanchasari.nelumbo.com.co/auth0loginCallback' : 'https://sari.icancha.com/auth0loginCallback',
        },
    },
};
